<template>
  <vx-card>
    <div class="vx-col w-full">
      <span>
        <h3>
          <b>{{ action }} {{ title }}</b>
        </h3>
      </span>
      <div class="vx-row m-10">
        <div class="vx-col w-1/4">
          <h4>Delivery Type</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="deliveryType"
            :options="optionDeliveryType"
            :show-labels="false"
            :multiple="false"
            :disabled="action === 'Edit'"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="deliveryType"
            v-validate="'required'"
            :custom-label="customLabelOptionDeliveryType"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('deliveryType')"
            >{{ errors.first("deliveryType") }}</span
          >
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 2">
        <div class="vx-col w-1/4">
          <h4>Delivery Code</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            v-validate="'required'"
            :disabled="action === 'Edit'"
            name="deliveryCode"
            v-model="data.delivery_code"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('deliveryCode')"
            >{{ errors.first("deliveryCode") }}</span
          >
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 2">
        <div class="vx-col w-1/4">
          <h4>Delivery Name</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            v-validate="'required'"
            name="deliveryName"
            v-model="data.delivery_name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('deliveryName')"
            >{{ errors.first("deliveryName") }}</span
          >
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>Vehicle Number</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            :disabled="action === 'Edit'"
            v-validate="'required'"
            name="vehicleNumber"
            v-model="data.vehicle_number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('vehicleNumber')"
            >{{ errors.first("vehicleNumber") }}</span
          >
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>Vehicle Name</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="vehicleName"
            :options="vehicleOption"
            :show-labels="false"
            :searchable="true"
            :allow-empty="true"
            :group-select="true"
            :internal-search="true"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="vehicleName"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('vehicleName')"
            >{{ errors.first("vehicleName") }}</span
          >
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>OwnerShip</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="data.ownership"
            :options="optionOwnership"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            required
            name="Ownership"
            v-validate="'required'"
            :custom-label="customLableOwnership"
          />
          <span class="text-danger text-sm" v-show="errors.has('Ownership')">{{
            errors.first("Ownership")
          }}</span>
        </div>
      </div>
      <div class="vx-row m-10" v-if="data.ownership.id == 2">
        <div class="vx-col w-1/4">
          <h4>Supplier</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="supplierItem"
            :options="optionSupplier"
            :show-labels="false"
            :searchable="true"
            :allow-empty="true"
            :group-select="true"
            :internal-search="false"
            @search-change="handleSearchSupplier"
            :max-height="160"
            required
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="supplier"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('supplier')">{{
            errors.first("supplier")
          }}</span>
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>Territory</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="selectedTerritory"
            :options="optionTerritory"
            :show-labels="false"
            :searchable="true"
            :allow-empty="true"
            :group-select="true"
            :internal-search="true"
            multiple
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            v-validate="'required'"
            name="territory"
            @select="handleSelectTerritory"
          />
          <span class="text-danger text-sm" v-show="errors.has('territory')">{{
            errors.first("territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>Operation Zone</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="operationZone"
            :options="optionOperationZone"
            :show-labels="false"
            :searchable="true"
            multiple
            :allow-empty="true"
            :group-select="true"
            :internal-search="false"
            @search-change="handleSearchOperationZone"
            :max-height="160"
            required
            placeholder=" Type to search"
            track-by="id"
            label="operationZone"
            name="operationZone"
            v-validate="'required'"
            :custom-label="customLabelOptionOperationZone"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('operationZone')"
            >{{ errors.first("operationZone") }}</span
          >
        </div>
      </div>

      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4">
          <h4>Capacity :</h4>
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4 ml-3">
          <h4>Length (cm)</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            name="capacityLength"
            type="number"
            v-model="data.capacity_length"
            @input="calculateVolume"
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4 ml-3">
          <h4>Width (cm)</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            name="capacityWidth"
            type="number"
            v-model="data.capacity_width"
            @input="calculateVolume"
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4 ml-3">
          <h4>Height (cm)</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            name="capacityHeight"
            type="number"
            v-model="data.capacity_height"
            @input="calculateVolume"
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4 ml-3">
          <h4>Volume (cm<sup>3</sup>)</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            v-validate="'required'"
            name="Capacity Volume"
            type="number"
            v-model="data.capacity_volume"
            readonly
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 1">
        <div class="vx-col w-1/4 ml-3">
          <h4>Weight (Kg)</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            v-validate="'required'"
            name="Capacity Weight"
            type="number"
            v-model="data.capacity_weight"
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 3">
        <div class="vx-col w-1/4">
          <h4>3<sup>rd</sup> Party Code</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            :disabled="action === 'Edit'"
            name="partyCode"
            v-model="data.party_code"
          />
        </div>
      </div>
      <div class="vx-row m-10" v-if="deliveryType.id == 3">
        <div class="vx-col w-1/4">
          <h4>3<sup>rd</sup> Party Name</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            v-validate="'required'"
            name="partyName"
            v-model="data.party_name"
          />
          <span class="text-danger text-sm" v-show="errors.has('partyName')">{{
            errors.first("partyName")
          }}</span>
        </div>
      </div>
      <div class="vx-row m-10">
        <div class="vx-col w-1/4">
          <h4>Allow Vehicle Class</h4>
        </div>
        <div class="vx-col w-1/2">
          <multiselect
            class="selectExample"
            v-model="vehicleClass"
            :options="optionVehicleClass"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="operationZone"
            name="operationZone"
            :custom-label="customLabelOptionVehicleClass"
          />
        </div>
      </div>
      <div class="vx-row m-10">
        <div class="vx-col w-1/4">
          <h4>Status</h4>
        </div>
        <div class="vx-col w-1/2">
          <vs-switch
            color="primary"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="status"
          >
            <span slot="on">Active</span>
            <span slot="off">Inactive</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row m-10">
        <div class="vx-col w-1/4">
          <div>
            <h4>Attachment</h4>
          </div>
          <div>
            <small style="color: red">(only: jpg, jpeg, pdf,png)</small>
          </div>
        </div>
        <div class="vx-col w-1/2">
          <div class="vx-col w-1/2">
            <!-- only accept "jpg", "jpeg", "pdf, "png" -->
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <!-- display: block -->
      <vs-divider style="width: 60%; margin-left: 30%">
        List Attachment
      </vs-divider>
      <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
        <table class="vs-table vs-table--tbody-table">
          <template>
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
              v-for="(tr, i) in fileAttachment"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="handleShowAttachment(tr)"
                  />
                </vx-tooltip>
              </td>

              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(tr, i)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleClose"
            >Close</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
// import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import moment from "moment";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    // ArrowUpDownBold,
  },
  data() {
    return {
      action: this.$route.query.action,
      id: this.$route.query.id,
      IDs: 0,
      title: "Vehicle",
      data: {
        id: 0,
        //LCO
        delivery_code: "",
        delivery_name: "",

        //FCO
        vehicle_number: "",
        vehicle_name: "",
        ownership: { name: "", id: null },
        capacity_length: null,
        capacity_width: null,
        capacity_height: null,
        capacity_volume: null,
        capacity_weight: null,

        //3rd
        party_code: "",
        party_name: "",
      },
      submitted: false,
      status: true,

      // select
      operationZone: [],
      optionOperationZone: [],

      selectedTerritory: [],
      optionTerritory: [],

      vehicleClass: { Name: "", id: null },
      optionVehicleClass: [],

      supplierItem: {},
      optionSupplier: [],

      deliveryType: { Name: "Type to search", id: null },

      optionDeliveryType: [],
      optionOwnership: [
        { name: "Internal", id: 1 },
        { name: "External", id: 2 },
      ],
      attachment: "",
      fileAttachment: [],
      IDAttachment: [],

      vehicleName: null,
      vehicleOption: [],
    };
  },
  filters: {
    // string to int
    toInt(value) {
      return parseInt(value);
    },
  },
  methods: {
    customLabelOptionDeliveryType({ Name }) {
      return `${Name}`;
    },
    customLabelOptionOperationZone({ name }) {
      return `${name}`;
    },
    customLableOwnership({ name }) {
      return `${name}`;
    },
    customLabelOptionVehicleClass({ Name }) {
      return `${Name}`;
    },
    calculateVolume() {
      const length = parseFloat(this.data.capacity_length);
      const width = parseFloat(this.data.capacity_width);
      const height = parseFloat(this.data.capacity_height);

      if (!isNaN(length) && !isNaN(width) && !isNaN(height)) {
        const volume = length * width * height;
        this.data.capacity_volume = volume.toString();
      }
    },
    async getData(id) {
      this.$vs.loading();
      let results = await this.$http.get(`/api/wms/v1/delivery-code/${id}`);

      if (results.code === 200) {
        let data = results.data;
        this.data.id = data.id;
        this.deliveryType.id = data.records.DeliveryType
          ? data.records.DeliveryType == "fco"
            ? 1
            : data.records.DeliveryType == "lco"
            ? 2
            : 3
          : 3;
        this.deliveryType.Name = data.records.DeliveryType
          ? data.records.DeliveryType == "fco"
            ? "FCO"
            : data.records.DeliveryType == "lco"
            ? "LCO"
            : "3rd Party"
          : "3rd Party";
        if (data.records.DeliveryType == "fco") {
          this.data.vehicle_name = data.records.Name;
          this.data.vehicle_number = data.records.Number;
          this.data.ownership.name = data.records.Ownership;
          this.data.ownership.id = data.records.Ownership == "Internal" ? 1 : 2;
          this.data.ownership.name = data.records.Ownership;
          this.operationZone = data.zones
            ? data.zones.length > 0
              ? data.zones
              : [{ name: "", id: 0 }]
            : [{ name: "", id: 0 }];

          if (this.data.ownership.id == 2) {
            this.supplierItem.id = data.records.Supplier.id;
            this.supplierItem.name = data.records.Supplier.name;
          } else {
            this.supplierItem.id = 0;
            this.supplierItem.name = "";
          }
          if (data.territories != null) {
            data.territories.forEach((element) => {
              element.id = element.territory_id;
            });
          }
          this.selectedTerritory = data.territories
            ? data.territories.length > 0
              ? data.territories
              : []
            : [];

          this.data.capacity_length = data.records.CapacityLength;
          this.data.capacity_height = data.records.CapacityHeight;
          this.data.capacity_width = data.records.CapacityWidth;
          this.data.capacity_volume = data.records.CapacityVolume;
          this.data.capacity_weight = data.records.CapacityWeight;
        } else if (data.records.DeliveryType == "lco") {
          this.data.delivery_code = data.records.DeliveryCode;
          this.data.delivery_name = data.records.DeliveryName;
        } else {
          this.data.party_code = data.records.PartyCode;
          this.data.party_name = data.records.PartyName;
        }

        this.status = data.records.Status;

        this.vehicleClass.Name = data.vehicle_class.Name;
        this.vehicleClass.id = data.vehicle_class.id;

        if (data.attachments != null) {
          data.attachments.forEach((el) => {
            this.fileAttachment.push({
              id: el.id,
              NameFile: el.Name,
              PathFile: el.Url,
            });
          });
        }

        this.$vs.loading.close();
      }
    },

    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.postData();
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Please fill all required field",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    paramData() {
      let ZoneItem = [];
      this.operationZone.forEach((element) => {
        ZoneItem.push({
          id: element.id,
          name: element.name,
        });
      });
      let TerritoryItem = [];
      this.selectedTerritory.forEach((element) => {
        TerritoryItem.push({
          id: element.id,
          name: element.name,
          code: element.code,
        });
      });
      let data = {
        id: this.data.id,
        delivery_type: this.deliveryType.Name,
        ownership: this.data.ownership.name,
        vehicle_class_id: this.vehicleClass.id,
        delivery_code: this.data.delivery_code,
        delivery_name: this.data.delivery_name,
        vehicle_number: this.data.vehicle_number,
        vehicle_name: this.vehicleName ? this.vehicleName.name : "",
        supplier_id: this.supplierItem.id,
        zones: ZoneItem,
        territories: TerritoryItem,

        capacity_length: parseFloat(this.data.capacity_length),
        capacity_width: parseFloat(this.data.capacity_width),
        capacity_height: parseFloat(this.data.capacity_height),
        capacity_volume: parseFloat(this.data.capacity_volume),
        capacity_weight: parseFloat(this.data.capacity_weight),

        party_code: this.data.party_code,
        party_name: this.data.party_name,
        status: this.status,
      };

      if (this.id != null) {
        data.id = parseInt(this.id);
      }

      return data;
    },
    postData() {
      this.$http
        .post("/api/wms/v1/delivery-code/update", this.paramData(), {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Delivery Code was Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.IDs = resp.data.id;
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
      this.$vs.loading.close();
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "delivery-code" });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/territories").then((resp) => {
        if (resp.code == 200) {
          this.optionTerritory = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Territory option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },

    getOptionDeliveryType() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/delivery-code/delivery-type").then((resp) => {
        if (resp.code == 200) {
          this.optionDeliveryType = resp.data.records.filter(
            (item) => item.id !== 4
          );
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Delivery Type option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    getOptionVehicleClass() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/delivery-code/vehicle-class").then((resp) => {
        if (resp.code == 200) {
          this.optionVehicleClass = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Vehicle Class option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    getOptionSupplier(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/delivery-code/supplier", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async getOptionOperationZone(params) {
      this.$vs.loading();
      await this.$http
        .get("/api/wms/v1/delivery-code/operation-zone", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperationZone = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Zones option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async handleSearchOperationZone(query, id) {
      let optionOperationZones = await this.getOptionOperationZone({
        territory_name: this.selectedTerritory.name,
        search: query,
        length: 10,
      });
      if (optionOperationZones.data.records != null) {
        // add all option
        optionOperationZones.data.records.unshift({
          id: 0,
          name: "All",
        });
        this.optionOperationZone = optionOperationZones.data.records;
      } else {
        this.optionOperationZone = [];
      }
    },
    async handleSearchSupplier(query) {
      let optionSuppliers = await this.getOptionSupplier({
        search: query,
        length: 10,
      });
      if (optionSuppliers.data.records != null) {
        // add all option
        optionSuppliers.data.records.unshift({
          id: 0,
          name: "All",
        });
        this.optionSupplier = optionSuppliers.data.records;
      } else {
        this.optionSupplier = [];
      }
    },
    handleSelectTerritory() {
      let TerritoryName = [];
      this.selectedTerritory.forEach((element) => {
        TerritoryName.push(element.name);
      });
      this.getOptionOperationZone({
        territory_name: TerritoryName,
      });
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.file.length; i++) {
          if (
            this.file[i].type != "application/pdf" &&
            this.file[i].type != "image/jpeg" &&
            this.file[i].type != "image/jpg" &&
            this.file[i].type != "image/png"
          ) {
            this.$vs.notify({
              title: "Error",
              text: "File type not allowed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }
        }

        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        this.handleSubmitAttachment();
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      console.log("&&&&&&&", file);
      let objectURL;
      // // console.log(file)
      objectURL = file.PathFile;
      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(data, index) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Warning",
        text: "Are you sure to delete this data?",
        accept: () => {
          this.$vs.loading();

          this.$http
            .delete("/api/wms/v1/delivery-code/attachment/" + data.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Data deleted successfully",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.fileAttachment = this.fileAttachment.filter((r, i) => {
                  return i != index;
                });
              }
              this.$vs.loading.close();
            });
        },
      });
    },
    handleSubmitAttachment() {
      console.log(this.fileAttachment);

      this.$vs.loading();
      if (this.fileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.fileAttachment.length; index++) {
          formData.append("attachments[]", this.fileAttachment[index].File);
        }
        formData.append("id", this.id);

        this.$http
          .post("/api/wms/v1/delivery-code/attachment", formData)
          .then((resp) => {
            if (resp.status == "OK") {
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading.close();
      }
    },
    getOptionVehicleName() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/vehicle-group").then((resp) => {
        if (resp.code == 200) {
          this.vehicleOption = resp.data.records;
          if (this.vehicleOption.length != 0) {
            //find data on vehicleOption where name = data.vehicle_name
            if (this.data.vehicle_name != null) {
              let data = this.vehicleOption.find(
                (element) => element.name == this.data.vehicle_name
              );
              if (data != null) {
                this.vehicleName = data;
              } else {
                this.vehicleName = null;
              }
            }
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Vehicle Name option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
  },

  async mounted() {
    if (this.$route.query.id != null) {
      await this.getData(this.$route.query.id);
    }
    this.getOptionVehicleClass();
    this.getOptionSupplier();
    this.getOptionDeliveryType();
    this.getTerritory();
    this.getOptionVehicleName();

    console.log("AAAA", this.optionOperationZone);
  },
  computed: {},
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
